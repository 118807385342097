/**** GLOBAL STYLES ****/
* {
  margin: 0;
  padding: 0;
  font-family: 'Arvo', serif;
  scroll-behavior: smooth;
}

h1, h2 {
  font-family: 'Maven Pro', sans-serif;
  text-transform: uppercase;
}

h1 {
  color: #f0ea49;
  font-size: 3.9rem;
}

h2 {
  font-size: 2rem;
  color: #2d2a32;
}

a:hover, button:hover {
  cursor: pointer;
}

button {
  padding: .8rem 1rem;
  background-color: #f0ea49;
  color: #ed4b64;
  border: none;
  border-bottom: .4rem solid #f26639;
  text-transform: uppercase;
  font-size: 1rem;
  font-style: italic;
  font-family: 'Maven Pro', sans-serif;
  font-weight: bold;
  width: fit-content;
}

footer {
  display: flex;
  justify-content: center;
  background-color: #F04d4d;
  padding: 3rem 1rem;
}

footer a, footer p  {
  display: inline-block;
  color: #fff;
  /* text-decoration: none; */
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Maven Pro', sans-serif;
}

/***** MOBILE STYLES ***/

@media only screen and (max-width: 576px) {

  hr {
    margin: 3rem 2rem;
  }

  /* header */

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header nav li {
    list-style: none;
    text-transform: uppercase;
  }

  header nav a {
    text-decoration: none;
    color: #2D2A32;
    font-weight: bold;
    font-family: 'Maven Pro', sans-serif;
  }

  .social-links {
    display: none;
  }

  .fa-bars {
    color: #ed4b64;
  }

  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    height: 3.5rem;
    margin: .8rem;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .menu-toggle {
    display: inline-block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #333;
  }

  .menu-toggle:hover, .menu-toggle:focus {
    color: #c00;
  }

  .main-menu {
    position: absolute;
    display: none;
    right: -200px;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    z-index: 999;
    -webkit-animiation: slideIn 1s ease;
    animation: slideIn 1s ease;
  }

  @-webkit-keyframes slideIn {
    from { right: -200px }
    to {right: 0px }
  }

  @keyframes slideIn {
    from { right: -200px }
    to {right: 0px }
  }

  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 2.5em 0 0;
    -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
      -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
            box-shadow: -8px 0 8px rgba(0,0,0,.5);
    min-height: 100%;
    width: 200px;
    background: rgba(237, 75, 111, .95);
  }

  .main-menu a {
    display: block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #f0ea49;
    text-decoration: none;
    border-bottom: 1px solid #383838;
  }

  .main-menu li:first-child a {
    border-top: 1px solid #383838;
  }

  .main-menu a:hover, .main-menu a:focus {
    background: #333;
    text-decoration: underline;
  }

  .main-menu .menu-close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .main-menu:target, .main-menu[aria-expanded="true"] {
    display: block;
    right: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
  }

  .main-menu:target .menu-close,
  .main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
  }

  .main-menu:target ul,
  .main-menu[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
  }

  .main-menu:target + .backdrop, .main-menu[aria-expanded="true"] + .backdrop{
    position: absolute;
    display: block;  
    right: 0;
    top: 0;
    width: 101%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0,0,0,.25);
    cursor: default;
  }

  @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
      position: fixed;
    }
  }


  /* hero */

  .hero-left {
    display: grid;
  }

  .triangle-topleft {
    height: 0;
    border-top: 375px solid #f04d4d;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .triangle-bottomright {
    height: 0;
    border-bottom: 375px solid #ed4b64;
    border-left: 375px solid transparent;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .main-box {
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-column-start: 1;
    padding-left: 1rem;
  }

  .main-side {
    display: none;
  }

  .below-hero-hr {
    background: #f04d4d;
    height: .625rem;
    margin: 0 1rem;
    border: none;
    display: none;
  }


  /* about */

  .about {
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .about img {
    width: 100%;
    display: none;
  }

  .about-right {
    margin: 1.5rem 0;
  }

  .introduction {
    display: flex;
  }

  .tech, .tools {
    margin-bottom: 1rem;
  }

  .tech ul, .tools ul {
    padding: 1rem 0 0 3rem;
    line-height: 2;
    list-style: square;
  }

  .tech h4, .tools h4 {
    color: #f04d4d;
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.5rem;
  }

  .about-button {
    display: flex;
    justify-content: center;
    margin-top: 1.8rem;
  }

  /* projects */

  .projects-container {
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .project {
    margin: 2rem 0 4rem 0;
  }

  .project-title {
    padding: .8rem .5rem;
  }

  .project-title h3 {
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
  }

  .project img {
    width: 100%;
  }

  .project p {
    display: block;
    margin: .7rem .6rem;
    text-align: center;
  }

  .project h4 {
    text-align: center;
  }

  .project a {
    color: #f04d4d;
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
  }

  .project-spacer {
    display: inline;
    margin: 0 .7rem;
  }

  .github-cta {
    display: flex;
    justify-content: center;
    background: #f0ea49;
    padding: 2rem 1rem;
    color: #f04d4d;
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
  }

  /* you see me i be work work work work work */

  .work {
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .experience {
    margin: 1rem 0 1rem 2rem;
    line-height: 2;
    list-style: square;
  }

  .experience li:hover {
    color: #ed4b64;
    cursor: pointer;
  }

  .job-blurb {
    background-color: #f0ea49;
    padding: 2rem;
  }

  .job {
    line-height: 1.5;
  }

  .job h4 {
    font-weight: normal;
  }

  .job-label {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
    line-height: 1.5;
    font-family: 'Maven Pro', sans-serif;
  }

  /* contact */

  .contact {
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .contact-info {
    margin: 1rem 0 0 2rem;
  }

  .contact-info a {
    color: #2d2a32;
  }

  .contact-info a:hover {
    color: #f26639;
  }

  .contact-method {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
    line-height: 1.5;
    font-family: 'Maven Pro', sans-serif;
  }
}


/****** DESKTOP STYLING ****/


@media only screen and (min-width: 576px) and (max-width: 940px) {
   /* header */

   header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header nav li {
    list-style: none;
    text-transform: uppercase;
  }

  header nav a {
    text-decoration: none;
    color: #2D2A32;
    font-weight: bold;
    font-family: 'Maven Pro', sans-serif;
  }

  .social-links {
    display: none;
  }

  .fa-bars {
    color: #ed4b64;
  }

  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    height: 3.5rem;
    margin: .8rem;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .menu-toggle {
    display: inline-block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #333;
  }

  .menu-toggle:hover, .menu-toggle:focus {
    color: #c00;
  }

  .main-menu {
    position: absolute;
    display: none;
    right: -200px;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    z-index: 999;
    -webkit-animiation: slideIn 1s ease;
    animation: slideIn 1s ease;
  }

  @-webkit-keyframes slideIn {
    from { right: -200px }
    to {right: 0px }
  }

  @keyframes slideIn {
    from { right: -200px }
    to {right: 0px }
  }

  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 2.5em 0 0;
    -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
      -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
            box-shadow: -8px 0 8px rgba(0,0,0,.5);
    min-height: 100%;
    width: 200px;
    background: rgba(237, 75, 111, .95);
  }

  .main-menu a {
    display: block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #f0ea49;
    text-decoration: none;
    border-bottom: 1px solid #383838;
  }

  .main-menu li:first-child a {
    border-top: 1px solid #383838;
  }

  .main-menu a:hover, .main-menu a:focus {
    background: #333;
    text-decoration: underline;
  }

  .main-menu .menu-close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .main-menu:target, .main-menu[aria-expanded="true"] {
    display: block;
    right: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
  }

  .main-menu:target .menu-close,
  .main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
  }

  .main-menu:target ul,
  .main-menu[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
  }

  .main-menu:target + .backdrop, .main-menu[aria-expanded="true"] + .backdrop{
    position: absolute;
    display: block;  
    right: 0;
    top: 0;
    width: 101%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0,0,0,.25);
    cursor: default;
  }

  @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
      position: fixed;
    }
  }

  .hero-left {
    display: grid;
  }

  .triangle-topleft {
    height: 0;
    border-top: 375px solid #f04d4d;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .triangle-bottomright {
    height: 0;
    border-bottom: 375px solid #ed4b64;
    border-left: 375px solid transparent;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .main-box {
    display: flex;
    align-items: center;
    grid-row-start: 1;
    grid-column-start: 1;
    padding-left: 1rem;
  }

  .main-side {
    display: none;
  }

  .below-hero-hr {
    background: #f04d4d;
    height: .625rem;
    margin: 0 1rem;
    border: none;
    display: none;
  }

  .about {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .about img {
    width: 100%;
  }

  .about-left {
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    margin: 1.5rem auto;
  }

  .tools-tech {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
  }

  .introduction {
    display: flex;
    justify-content: center;
    background-color: #f0ea49;
    padding: 20px 25px;
  }

  .tech, .tools {
    margin: 1rem;
  }

  .tech ul, .tools ul {
    padding: 1rem 0 0 3rem;
    line-height: 2;
    list-style: square;
  }

  .tech h4, .tools h4 {
    color: #f04d4d;
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.5rem;
  }

  .about-button {
    display: flex;
    justify-content: center;
    margin-top: 1.8rem;
  }


  .projects-container {
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .project {
    margin: 2rem 0 4rem 0;
  }

  .project-title {
    padding: .8rem .5rem;
  }

  .project-title h3 {
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
  }

  .project img {
    width: 100%;
  }

  .project p {
    display: block;
    margin: .7rem .6rem;
    text-align: center;
  }

  .project h4 {
    text-align: center;
  }

  .project a {
    color: #f04d4d;
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
  }

  .project-spacer {
    display: inline;
    margin: 0 .7rem;
  }

  .github-cta {
    display: flex;
    justify-content: center;
    background: #f0ea49;
    padding: 2rem 1rem;
    color: #f04d4d;
    text-transform: uppercase;
    font-family: 'Maven Pro', sans-serif;
    font-weight: bold;
  }

  .work {
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .experience {
    margin: 1rem 0 1rem 2rem;
    line-height: 2;
    list-style: square;
  }

  .experience li:hover {
    color: #ed4b64;
    cursor: pointer;
  }

  .job-blurb {
    background-color: #f0ea49;
    padding: 2rem;
  }

  .job {
    line-height: 1.5;
  }

  .job h4 {
    font-weight: normal;
  }

  .job-label {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
    line-height: 1.5;
    font-family: 'Maven Pro', sans-serif;
  }

  /* contact */

  .contact {
    margin: 2.5rem 0rem;
    padding: 0 1.2rem;
  }

  .contact-info {
    margin: 1rem 0 0 2rem;
  }

  .contact-info a {
    color: #2d2a32;
  }

  .contact-info a:hover {
    color: #f26639;
  }

  .contact-method {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
    line-height: 1.5;
    font-family: 'Maven Pro', sans-serif;
  }
}

@media only screen and (min-width: 940px) and (max-width: 1190px) {
  hr {
    margin: 8rem 3rem;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.3rem 3rem;
    padding-right: .625rem;
  }

  header nav li {
    list-style: none;
    text-transform: uppercase;
  }

  header nav a {
    text-decoration: none;
    color: #2D2A32;
    font-weight: bold;
    font-family: 'Maven Pro', sans-serif;
  }

  .social-links {
    display: none;
  }

  .fa-bars {
    color: #ed4b64;
  }

  .logo-mobile {
    display: none;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .menu-toggle {
    display: inline-block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #333;
  }

  .menu-toggle:hover, .menu-toggle:focus {
    color: #c00;
  }

  .main-menu {
    position: absolute;
    display: none;
    right: -200px;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: visible;
    z-index: 999;
    -webkit-animiation: slideIn 1s ease;
    animation: slideIn 1s ease;
  }

  @-webkit-keyframes slideIn {
    from { right: -200px }
    to {right: 0px }
  }

  @keyframes slideIn {
    from { right: -200px }
    to {right: 0px }
  }

  .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 2.5em 0 0;
    -webkit-box-shadow: -8px 0 8px rgba(0,0,0,.5);
      -moz-box-shadow: -8px 0 8px rgba(0,0,0,.5);
            box-shadow: -8px 0 8px rgba(0,0,0,.5);
    min-height: 100%;
    width: 200px;
    background: rgba(237, 75, 111, .95);
  }

  .main-menu a {
    display: block;
    padding: .75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #f0ea49;
    text-decoration: none;
    border-bottom: 1px solid #383838;
  }

  .main-menu li:first-child a {
    border-top: 1px solid #383838;
  }

  .main-menu a:hover, .main-menu a:focus {
    background: #333;
    text-decoration: underline;
  }

  .main-menu .menu-close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .main-menu:target, .main-menu[aria-expanded="true"] {
    display: block;
    right: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
    box-shadow: 3px 0 12px rgba(0,0,0,.25);
  }

  .main-menu:target .menu-close,
  .main-menu[aria-expanded="true"] .menu-close {
    z-index: 1001;
  }

  .main-menu:target ul,
  .main-menu[aria-expanded="true"] ul {
    position: relative;
    z-index: 1000;
  }

  .main-menu:target + .backdrop, .main-menu[aria-expanded="true"] + .backdrop{
    position: absolute;
    display: block;  
    right: 0;
    top: 0;
    width: 101%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0,0,0,.25);
    cursor: default;
  }

  @supports (position: fixed) {
    .main-menu,
    .main-menu:target + .backdrop,
    .main-menu[aria-expanded="true"] + .backdrop{
      position: fixed;
    }
  }
  /** hero **/
  
  .hero {
    display: flex;
    justify-content: center;
    margin: 4rem 4rem 10rem 4rem;
}

.hero-left {
    display: grid;
    grid-template-columns: [left] 10rem [right] 30rem;
    grid-template-rows: [top] 15rem [bottom] 18rem;
}

.hero-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
}

.main-side p {
  font-size: 1.125rem;
}

.main-box {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 3;
}

.main-box h1 {
    font-size: 68px;
    text-transform: uppercase;
}
  
.triangle-topleft {
  height: 0;
  border-top: 33.044rem solid #f04d4d;
  border-right: 40.7rem solid transparent;
  grid-column-start: 1;
  grid-row-start: 1;
  /* grid-row-end: right; */
}

.triangle-bottomright {
  height: 0;
  border-bottom: 33rem solid #ed4b64;
  border-left: 40.625rem solid transparent;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
} 
  
  
  /** about **/
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4rem;
}

.about h4 {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: 'Maven Pro', sans-serif;
  color: #ed4b64;
}

.about-left {
  display: grid;
  grid-template-columns: [left] 5rem [middle] 6rem [right] 14rem;
  grid-template-rows: [top] 50% [middle] 20% [bottom] 20%;
  margin-right: 5rem;
}

.about-left img {
  width: 25rem;
  grid-column-start: 1;
}

.about-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 10rem; */
}

.tools-tech {
  display: flex;
  margin-bottom: 3rem;
}

.introduction {
  /* grid-column-start: 1; */
  grid-column-start: 2;
  grid-row-end: 3;
    background-color: #f0ea49;
    padding: 20px 25px;
    width: 350px;
    text-align: center;
  
}

.tech {
    border-top: 6px solid #ed4b64;
    padding-top: 20px;
    margin-right: 6rem;
}

.tech ul, .tools ul {
  padding-left: 2rem;
  padding-top: 1rem;
}

.tech li, .tools li {
    line-height: 1.7;
    list-style: square;
}

.tools {
    border-top: 6px solid #f26639;
    padding-top: 20px;
}


  /** projects **/

  .projects-container {
    margin: 3rem;
  }

  .projects {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 2rem;
}
.project {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-bottom: 100px;
  }

.project-title {
    display: flex;
    align-items: center;
    height: 60px;
    text-transform: uppercase;
    color: #2d2a32;
    padding-left: 15px;
  }
  
  .project-title h3 {
    margin-bottom: 0px;
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.4rem;
}

.project img {
  width: 100%;
}

.project p {
  display: block;
  margin: 1.3rem .6rem;
  text-align: center;
}

.project h4 {
  text-align: center;
}

.project a {
  color: #f04d4d;
  text-transform: uppercase;
  font-family: 'Maven Pro', sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
}

.project-spacer {
  display: inline;
  margin: 0 1rem;
}

.github-cta {
  display: flex;
  justify-content: center;
}

.github-cta-button {
  padding: 2rem 1rem;
  font-size: 1.125rem;
}

.fa-chevron-right {
  color: #f04d4d;
  margin-left: .2rem;
}

  /** work experience **/
  .work {
    margin: 4rem;
    padding: 0 1.2rem;
  }

  .experience {
    margin: 1rem 0 1rem 2rem;
    line-height: 2;
    list-style: square;
  }

  .experience li:hover {
    color: #ed4b64;
    cursor: pointer;
  }

  .experience li::selection {
    color: #ed4b64;
  }

  .job-blurb {
    background-color: #f0ea49;
    padding: 2rem;
    margin: 2rem 4rem;
  }

  .job {
    line-height: 1.5;
  }

  .job h4 {
    font-weight: normal;
  }

  .job-label {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
    line-height: 1.5;
    font-family: 'Maven Pro', sans-serif;
  }
  /** contact **/

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem;
}

.contact-message {
  width: 40%;
  padding: 2.5rem;
}

.contact-message p {
  font-size: 1.8rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  border-left: 6px solid #f0ea49;
  padding: 2.5rem;
  margin-top: 1rem;
  line-height: 2;
  font-size: 1.125rem;
}

.contact-info a {
    color: #2d2a32;
}

.contact-info a:hover {
    color: #f26639;
}

.contact-method {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
}
}

@media only screen and (min-width: 1190px) {
  
  hr {
    margin: 8rem 3rem;
  }
  
  /* header */
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.0625rem;
    max-width: 95%;
    margin: 1.3rem 3rem;
    padding-right: .625rem;
    border-right: solid .5rem #f26639;
  }

  .menu-toggle {
    display: none;
  }

  .menu-close {
    display: none;
  }

  .main-menu {
    display: flex;
    position: relative;
  }
  
  .logo-mobile {
    display: none;
  }
  
  nav {
    text-align: center;
  }
  
  nav a {
    text-decoration: none;
    color: #2d2a32;
    font-weight: bold;
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.125rem;
  }

  nav a:hover {
    color: #f26639;
  }
  
  nav li {
    display: inline-block;
    padding: 0px 15px;
    border-right: 3px solid #ed4b64;
    list-style: none;
    text-transform: uppercase;
    margin: 15px 0px;
  }
  
  nav li:nth-child(5n) {
    border-right: none;
  }
  
  /** hero **/
  
  .hero {
    display: flex;
    justify-content: center;
    margin: 4rem 4rem 10rem 4rem;
}

.hero-left {
    display: grid;
    grid-template-columns: [left] 10rem [right] 30rem;
    grid-template-rows: [top] 15rem [bottom] 18rem;
}

.hero-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
}

.main-side p {
  display: block;
  margin-top: 2rem;
  font-size: 1.125rem;
}

.main-box {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 3;
}

.main-box h1 {
    font-size: 68px;
    text-transform: uppercase;
}
  
.triangle-topleft {
  height: 0;
  border-top: 33.044rem solid #f04d4d;
  border-right: 40.7rem solid transparent;
  grid-column-start: 1;
  grid-row-start: 1;
  /* grid-row-end: right; */
}

.triangle-bottomright {
  height: 0;
  border-bottom: 33rem solid #ed4b64;
  border-left: 40.625rem solid transparent;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
} 
  
  
  /** about **/
  .about {
    display: flex;
    justify-content: center;
    margin: 4rem;
}

.about h4 {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: 'Maven Pro', sans-serif;
  color: #ed4b64;
}

.about-left {
  display: grid;
  grid-template-columns: [left] 5rem [middle] 6rem [right] 14rem;
  grid-template-rows: [top] 50% [middle] 20% [bottom] 20%;
  margin-right: 5rem;
}

.about-left img {
  width: 25rem;
  grid-column-start: 1;
}

.about-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10rem;
}

.tools-tech {
  display: flex;
  margin-bottom: 3rem;
}

.introduction {
  /* grid-column-start: 1; */
  grid-column-start: 2;
  grid-row-end: 3;
    background-color: #f0ea49;
    padding: 20px 25px;
    width: 350px;
    text-align: center;
  
}

.tech {
    border-top: 6px solid #ed4b64;
    padding-top: 20px;
    margin-right: 6rem;
}

.tech ul, .tools ul {
  padding-left: 2rem;
  padding-top: 1rem;
}

.tech li, .tools li {
    line-height: 1.7;
    list-style: square;
}

.tools {
    border-top: 6px solid #f26639;
    padding-top: 20px;
}


  /** projects **/

  .projects-container {
    margin: 3rem;
  }

  .projects {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 2rem;
}
.project {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-bottom: 100px;
  }

.project-title {
    display: flex;
    align-items: center;
    height: 60px;
    text-transform: uppercase;
    color: #2d2a32;
    padding-left: 15px;
  }
  
  .project-title h3 {
    margin-bottom: 0px;
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.4rem;
}

.project img {
  width: 100%;
}

.project p {
  display: block;
  margin: 1.3rem .6rem;
  text-align: center;
}

.project h4 {
  text-align: center;
}

.project a {
  color: #f04d4d;
  text-transform: uppercase;
  font-family: 'Maven Pro', sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
}

.project-spacer {
  display: inline;
  margin: 0 1rem;
}

.github-cta {
  display: flex;
  justify-content: center;
}

.github-cta-button {
  padding: 2rem 1rem;
  font-size: 1.125rem;
}

.fa-chevron-right {
  color: #f04d4d;
  margin-left: .2rem;
}

  /** work experience **/
  .work {
    margin: 4rem;
    padding: 0 1.2rem;
  }

  .experience {
    margin: 1rem 0 1rem 2rem;
    line-height: 2;
    list-style: square;
  }

  .experience li:hover {
    color: #ed4b64;
    cursor: pointer;
  }

  .experience li::selection {
    color: #ed4b64;
  }

  .job-blurb {
    background-color: #f0ea49;
    padding: 2rem;
    margin: 2rem 4rem;
  }

  .job {
    line-height: 1.5;
  }

  .job h4 {
    font-weight: normal;
  }

  .job-label {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
    line-height: 1.5;
    font-family: 'Maven Pro', sans-serif;
  }
  /** contact **/

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem;
}


.contact-message {
  width: 40%;
  padding: 2.5rem;
}

.contact-message p {
  font-size: 1.8rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  border-left: 6px solid #f0ea49;
  padding: 2.5rem;
  margin-top: 1rem;
  line-height: 2;
  font-size: 1.125rem;
}

.contact-info a {
    color: #2d2a32;
}

.contact-info a:hover {
    color: #f26639;
}

.contact-method {
    font-weight: bold;
    color: #ed4b64;
    text-transform: uppercase;
}
  
}

.backdrop {
  width: 101%;
}



/**** SPLASH STYLES ****/

@media only screen and (max-width: 576px) {
  #splash-main {
    display: flex;
    flex-direction: column;
  }

  #splash-sidebar {
    background-color: #f04d4d;
    height: 30vh;
  }

  #splash-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #splash-content-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px 15px 15px;
  }

  #splash-content-left img {
    border-top: 1rem solid #f0ea49;
    margin-top: -25vh;
    width: 70vw;
    max-width: 400px;
  }

  #splash-content-right h1 {
    color: #ed4b64;
    margin: 25px 0 25px 0;
    font-size: 42px;
    text-align: center;
  }

  #splash-content-right p {
    font-size: 18px;
    color: #3e3e3e;
    margin: 0 15px 25px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1190px) {
  #splash-main {
    height: 100vh;
    display: flex;
  }
  
  #splash-sidebar {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #f04d4d;
    max-width: 400px;
    padding: 35px;
  }
  
  #splash-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
  }
  
  #splash-content-right {
    padding-left: 35px;
    margin-right: 40px;
  }
  
  #splash-content-left img {
    border-left: 1rem solid #f0ea49;
    margin-left: -75px;
    width: 30vw;
    max-width: 400px;
  }
  
  #splash-content-right h1 {
    color: #ed4b64;
    margin-bottom: 35px;
    font-size: 42px;
  }
  
  #splash-content-right p {
    font-size: 18px;
    color: #3e3e3e;
  }
  
  #splash-content-right button {
    margin-top: 35px;
    background-color: #ed4b64;
    color: #FEF9EF;
    font-size: 18px;
    border-bottom: .6rem solid #f0ea49;
  }
}

@media only screen and (min-width: 1190px) {
  #splash-main {
    height: 100vh;
    display: flex;
  }
  
  #splash-sidebar {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #f04d4d;
    max-width: 400px;
    padding: 35px;
  }
  
  #splash-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
  }
  
  #splash-content-right {
    padding-left: 35px;
    margin-right: 40px;
  
  }
  
  #splash-content-left img {
    border-left: 1rem solid #f0ea49;
    margin-left: -75px;
    width: 30vw;
    max-width: 400px;
  
  }
  
  #splash-content-right h1 {
    color: #ed4b64;
    margin-bottom: 35px;
  }
  
  #splash-content-right p {
    font-size: 22px;
    color: #3e3e3e;
  }
  
  #splash-content-right button {
    margin-top: 35px;
    background-color: #ed4b64;
    color: #FEF9EF;
    font-size: 28px;
    border-bottom: .6rem solid #f0ea49;
  }
}
